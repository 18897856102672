import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Mentions légales`}</h1>
    <h3>{`Site obi2b.com`}</h3>
    <p>{`Directeur de la publication : `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`Thierry Chatel - tchatel@obi2b.com`}</code></pre></div>
    <p></p>
    <p>{`Obi2b est hébergé juridiquement par :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`CREALEAD - SA coopérative à conseil d'administration
Hôtel de la Coopération
55 rue Saint Cléophas
34070 Montpellier

Téléphone : 04 67 06 96 72

SIRET : 438 076 200 00023
TVA intra communautaire : FR56438076200
Organisme de formation numéro 91-34-04871-34, région Occitanie.`}</code></pre></div>
    <p></p>
    <p>{`Hébergement web : `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`DreamHost, LLC. – PMB #257 - 417 Associated Rd. - Brea, CA 92821 - USA`}</code></pre></div>
    <p></p>
    <p>{`Crédits photos : Jérémie François et Thierry Chatel`}</p>
    <h3>{`Utilisation des cookies et des données personnelles`}</h3>
    <p>{`Ce site n’utilise aucun cookie, même pas via un outil de suivi d’audience.`}<br />{`
Il ne recueille aucune donnée, qu’elle soit ou non personnelle.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react';
import SEO from '../../components/global/seo';
import OtherLayout from '../../components/other/other-layout';
import MentionMdx from '../../components/mention/mention.mdx';

import './index.scss';

const MentionPage = () => (
  <OtherLayout className="mention-page">
    <SEO title="Mentions légales" />

    <MentionMdx/>

  </OtherLayout>
);

export default MentionPage;
